<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="teamInfo.isDelete"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container fluid>
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12" sm="3">
              <v-chip
                pill
                :color="form.color"
                :dark="form.color === 'black'"
                class="ma-1"
                @click="show = !show"
              >
                <v-avatar left>
                  <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-avatar>
                팀컬러
              </v-chip>
            </v-col>
            <v-col cols="12" xs="12" sm="7">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <div style="width:230px">
                  <v-text-field
                    ref="txtTeamName"
                    v-model.trim="form.name"
                    v-validate="'required|max:10'"
                    :error-messages="errors.collect('name')"
                    data-vv-name="name"
                    required
                    label="부서명"
                    counter="10"
                    maxlength="10"
                    :readonly="!form.isDelete"
                    :disabled="!form.isDelete"
                    class="pr-3"
                    @keypress.enter="agree"
                    :error="isOverlabed"
                  ></v-text-field>
                </div>
                <!-- // ? 이 툴팁은 삭제불가한 팀인 경우만 보인다 -->
                <v-tooltip
                  v-show="!form.isDelete"
                  bottom
                  :color="form.color"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-show="!form.isDelete"
                      v-on="on"
                      small
                      class="pb-1"
                    >mdi-help-circle-outline</v-icon>
                  </template>
                  <span>{{ form.caption }}</span>
                </v-tooltip>
                <!-- // ? 중복체크 버튼은 삭제가능한 팀인 경우만 보인다 -->
                <v-btn
                  v-show="form.isDelete"
                  small
                  text
                  class="ma-2"
                  :color="mustChk ? 'error' : 'indigo'"
                  @click="form.isDelete ? chkTeamNameOverlap() : null"
                >
                  <v-icon small class="text-left">
                    {{ chkMulti ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                  중복체크
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="2" class="text-center">
              <!-- // ? 이 툴팁은 삭제가능한 팀인 경우만 보인다 -->
              <v-tooltip
                v-show="form.isDelete"
                bottom
                :color="form.color"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-show="form.isDelete"
                    v-on="on"
                    small
                    class="pb-1"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>{{ form.caption }}</span>
              </v-tooltip>
            </v-col>
            <v-expand-transition>
              <v-col v-show="show" cols="12" xs="12">
                <v-divider class="mb-2"></v-divider>
                <v-chip
                  pill
                  v-for="(color, i) in $colors"
                  :key="i"
                  :color="color"
                  :dark="color === 'black'"
                  class="ma-1"
                  @click="setTeamColor(color)"
                >
                  <v-avatar left>
                    <v-icon>mdi-checkbox-marked-circle</v-icon>
                  </v-avatar>
                  선택
                </v-chip>
              </v-col>
            </v-expand-transition>
            <v-col cols="12" xs="12">
              <v-divider class="my-2"></v-divider>
            </v-col>
            <v-col cols="12">
              <h4>소속팀원</h4>
              <v-card
                outlined
                elevation="0"
                max-height="180"
                min-height="120"
                class="overflow-auto text-body-2 pa-1"
              >
                <v-chip
                  v-for="(s, i) in selected"
                  :key="i"
                  :close="s.isDelete ? true : false"
                  @click:close="delSelected(s, i)"
                  color="white"
                  class="d-block my-0 ml-0 mr-8"
                >
                  {{ s.name }}
                  <span class="text-caption grey--text mx-1">
                    {{ ` (${s.email}) ` }}
                    {{ s.rank && s.position ? `${s.rank}|${s.position}` : `${s.rank}` || `${s.position}` }}
                  </span>
                </v-chip>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12">
              <h4 class="mt-1">공유자</h4>
              <v-card
                outlined
                elevation="0"
                max-height="180"
                min-height="120"
                class="overflow-auto text-body-2 pa-1"
              >
                <v-chip
                  v-for="(s, i) in unSelected"
                  :key="i"
                  color="white"
                  class="d-block my-0 ml-0 mr-8"
                  @click="addSelected(s, i)"
                >
                  <v-avatar left>
                    <v-icon small>mdi-checkbox-marked-circle</v-icon>
                  </v-avatar>
                  {{ s.name }}
                  <span class="text-caption grey--text mx-1">
                    {{ ` (${s.email}) ` }}
                    {{ s.rank && s.position ? `${s.rank}|${s.position}` : `${s.rank}` || `${s.position}` }}
                  </span>
                </v-chip><br>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'

// filters
import isSpKey from '@/filters/isSpecialCharacter'

export default {
  components: {
    baseModal
  },

  props: [ 'teamInfo' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200
    },
    // 참고: for Form
    form: {
      id: 0,
      name: '',
      color: '',
      caption: '',
      type: '', // 기본팀(A), M, U 타입
      staffs: [], // 등록/삭제할 유저
      oriStaffs: [], // 기존에 있던 유저
      isDelete: false,
      isChangedTeam: false, // 원본과 변동 여부(팀명,팀컬러)
      isChangedTeamStaff: false // 원본과 변동 여부(팀소속원변동)
    },
    selected: [], // 선택된 조직원
    oriSelected: [], // 선택된 조직원의 원본을 저장
    unSelected: [], // 선택되지 않은 조직원
    show: false, // 컬러 선택창을 보여주는 여부
    dictionary: {
      messages: ko.messages,
      attributes: {
        name: '팀명'
      }
    },
    mustChk: false, // 중복체크를 해야 하는지 여부
    chkMulti: false, // 참고: 팀명 중복 확인했는지 여부
    isOverlabed: false // 참고: 팀명이 중복 되었는지 여부
  }),

  watch: {
    'form.name': { // !! 팀명 자동감지 - 특수문자만 필터링
      handler: function (val, oldVal) {
        if (val.length > 0) { // 값이 있으면
          if (isSpKey(val)) {
            this.form.name = oldVal
            this.$refs.txtTeamName.lazyValue = oldVal
          }
          // 중복 체크 관련 초기화
          this.mustChk = true // 중복체크 해야 함
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false
        } else { // 내용이 아예 없으면 중복 체크하지 않아도 됨
          this.mustChk = false // 중복체크 안해도 됨
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false
        }
      }
    }
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    isSpKey, // 특수문자 필터링
    dummy () {
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화 함수
    initDialog () {
      // form validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.name = ''
      this.form.color = ''
      this.form.caption = ''
      this.form.type = ''
      this.form.staffs = []
      this.form.isDelete = false

      this.form.isChangedTeam = false // 중요: 얘도 초기화 해야하네
      this.form.isChangedTeamStaff = false // 중요: 얘도 초기화 해야하네

      this.mustChk = false
      this.chkMulti = false
      this.isOverlabed = false
    },
    open (title, options, team) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        this.initDialog()

        // 중요: props.teamInfo 에 넘어온 값을 넘긴다.
        // 여기서 넘기는게 확실한듯
        this.teamInfo = Object.assign(this.teamInfo, team)
        // 넘어온 정보가 있는 경우 매칭함
        if (this.teamInfo) {
          this.form.id = this.teamInfo.id
          this.form.name = this.teamInfo.name
          this.form.color = this.teamInfo.color
          this.form.caption = this.teamInfo.caption
          this.form.type = this.teamInfo.type
          this.form.isDelete = this.teamInfo.isDelete

          this.$nextTick(function () {
            this.mustChk = false // 중복체크 필요없는걸로
            this.chkMulti = true // 이미 중복체크 한걸로
            this.isOverlabed = false // 중복되지 않은걸로
          })
        }

        // 해당 팀에 속한 유저 리스트를 가져온다.
        this.getTeamStaffs()

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 등록 버튼
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // !! 팀명 중복체크 확인
        if (this.mustChk && !this.chkMulti) {
          this.isOverlabed = true // 중복여부를 가져오진 않았지만 수동으로 에러를 만든다.
          this.$refs.txtTeamName.focus()
          throw new Error('팀명 중복체크를 하셔야 합니다.')
        }

        // 변경된게 있는지 체크
        this.isChangedCheck()

        // !! 변경된게 있으면 진행한다.(팀정보가 변경되었거나, 팀소속원이 변경되었거나)
        if (this.form.isChangedTeam || this.form.isChangedTeamStaff) {
          // !! 선택된 조직원 목록을 매칭하자.
          this.form.staffs = this.selected
          this.form.caption = '' // 쓸데없으니 이넘은 비우자
          // console.log(this.form)

          // !! 필요한건 id,color,name,staffs,type(U인 경우만 변경,삭제 가능)
          // 주의: 팀정보(teams 테이블)와 팀에 소속된 팀원(teamstaffs 테이블) 둘 다 변경
          const { data } = await this.$axios.post(`admin/firm/updateTeamStaffs`, this.form)
          if (!data.success) throw new Error(`list error: ${data.message}`)
        }
        // 변경된게 없으면 그냥 꺼버림

        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 변경여부 검사
    isChangedCheck () {
      // !! 원본과 변동 비교: 변하는건 name, color, staffs 3개
      // 팀정보 중 color 비교
      if (this.form.color !== this.teamInfo.color) {
        this.form.isChangedTeam = true // 팀정보 수정만 변동
      }
      // 팀정보 중 name 비교
      if (this.form.name !== this.teamInfo.name) {
        this.form.isChangedTeam = true // 팀정보 수정만 변동
      }
      // 조직원 배열 비교
      if (this.oriSelected.length !== this.selected.length) { // 길이가 다르면 변경된 것
        this.form.isChangedTeamStaff = true // 팀소속원만 변동
      } else {
        // 길이가 같을 때가 문제
        // this.oriSelected는 변하지 않기 때문에 양방향이 필요없다.
        // 객체안의 배열에 대한 완전한 비교는 객체안의 내용을 일일히 비교하는 수밖에 없다.
        // 대신 우리의 경우 이메일만 비교하면 되므로 좀 낫다.
        for (let i = 0; i < this.oriSelected.length; i++) {
          if (this.oriSelected[i].email !== this.selected[i].email) {
            this.form.isChangedTeamStaff = true // 팀소속원만 변동
          }
        }
      }
    },
    // 삭제 버튼
    async remove () {
      // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
      const target = 'confirmDialog'
      const pop = await this.findParentRefs(this.$parent, target)
      if (!pop) throw new Error('팝업창을 열 수 없습니다.')
      // 찾았으면 팝업을 연다
      const msg = `업무부서와 해당 정보를 삭제하며, 다시 복구할 수 없습니다.이관할 정보가 있는지 확인하기 바랍니다.`
      if (await pop.open('삭제', msg, { color: 'error', width: 380 })) {
        const { data } = await this.$axios.get(`admin/firm/deleteTeam/${this.form.id}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)

        this.initDialog()
        this.resolve(true)
        this.dialog = false
      }
    },
    // 취소 버튼
    cancel () {
      // 다이얼로그를 닫으면서 초기화 해야 한다!
      this.initDialog()
      this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
      this.dialog = false
    },
    setTeamColor (color) {
      this.form.color = color
      this.show = false
    },
    // 참고: 선택된/선택되지 않은 조직원 리스트 패칭
    async getTeamStaffs () {
      try {
        const params = {
          id: this.form.id,
          type: this.form.type
        }
        const { data } = await this.$axios.get(`admin/firm/getTeamStaffs`, { params })
        if (!data.success) throw new Error(`list error: ${data.message}`)

        // !! 팀에 소속된/소속되지 않은 조직원 리스트를 가져온다.(갶은복사)
        this.selected = data.selectedStaffs
        this.unSelected = data.unSelectedStaffs

        // !! 소속된 조직원 리스트의 원본을 저장한다.
        // this.oriSelected = this.selected -> 같은 주소라 이렇면 안됨
        this.oriSelected = [ ...this.selected ] // 얉은 복사를 한다.
        this.form.oriStaffs = this.oriSelected // 전송할 폼에도 복사
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 선택한 조직원을 공유중인 조직원 리스트로 옮긴다.
    addSelected (s, i) {
      this.selected.push(s)
      this.unSelected.splice(i, 1)
    },
    // !! 선택한 조직원을 공유중인 조직원 리스트에서 삭제한다.
    delSelected (s, i) {
      this.unSelected.push(s)
      this.selected.splice(i, 1)
    },
    // !! 팀명 중복체크 함수
    async chkTeamNameOverlap () {
      try {
        if (this.form.name.length > 0) {
          const { data } = await this.$axios.get(`admin/firm/chkTeamNameOverlap/${this.form.name}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.chk) { // 중복 되었다
            this.mustChk = true
            this.chkMulti = false
            this.isOverlabed = true
            this.$refs.txtTeamName.focus()
            throw new Error(`이미 등록된 팀명 입니다.`)
          } else {
            this.mustChk = false
            this.chkMulti = true
            this.isOverlabed = false
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
    // ,
    // 참고: 팀명 텍스트박스의 keyup 이벤트 - 팀명 중복체크 리셋
    // async chkTeamNameOverlapReset (e) {
    //   // 체크했는지 여부와 중복여부를 리셋시킨다.
    //   this.chkMulti = false
    //   this.isOverlabed = false
    // }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
