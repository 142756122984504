<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container fluid class="pb-0">
          <v-row
            align="center"
            justify="center"
            no-gutters
          >
            <v-col cols="12" xs="12" sm="3">
              <v-chip
                pill
                :color="form.color"
                :dark="form.color === 'black'"
                class="ma-1"
                @click="show = !show"
              >
                <v-avatar left>
                  <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-avatar>
                팀컬러
              </v-chip>
            </v-col>
            <v-col cols="12" xs="12" sm="7">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <div style="width:230px">
                  <v-text-field
                    ref="txtTeamName"
                    v-model.trim="form.name"
                    v-validate="'required|max:10'"
                    :error-messages="errors.collect('name')"
                    data-vv-name="name"
                    autofocus
                    required
                    label="부서명"
                    maxlength="10"
                    counter="10"
                    class="pr-3"
                    @keypress.enter="agree"
                    :error="isOverlabed"
                  ></v-text-field>
                </div>
                <v-btn small text class="ma-2"
                  :color="mustChk ? 'error' : 'indigo'"
                  @click="chkTeamNameOverlap"
                >
                  <v-icon small class="text-left">
                    {{ chkMulti ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                  </v-icon>
                  중복체크
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="2" class="text-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    small
                    class="pb-1"
                  >mdi-help-circle-outline</v-icon>
                </template>
                <span>{{ form.caption }}</span>
              </v-tooltip>
            </v-col>
            <v-expand-transition>
              <v-col v-show="show" cols="12" xs="12">
                <v-divider class="mb-2"></v-divider>
                <v-chip
                  pill
                  v-for="(color, i) in $colors"
                  :key="i"
                  :color="color"
                  :dark="color === 'black'"
                  class="ma-1"
                  @click="setTeamColor(color)"
                >
                  <v-avatar left>
                    <v-icon>mdi-checkbox-marked-circle</v-icon>
                  </v-avatar>
                  선택
                </v-chip>
              </v-col>
            </v-expand-transition>
            <v-col cols="12" xs="12">
              <v-divider class="my-2"></v-divider>
            </v-col>
            <v-col cols="12">
              <h4>소속팀원</h4>
              <v-card
                outlined
                elevation="0"
                max-height="180"
                min-height="120"
                class="overflow-auto text-body-2 pa-1"
              >
                <v-chip
                  v-for="(s, i) in selected"
                  :key="i"
                  :close="s.isDelete ? true : false"
                  @click:close="delSelected(s, i)"
                  color="white"
                  class="d-block my-0 ml-0 mr-8"
                >
                  {{ s.name }}
                  <span class="text-caption grey--text mx-1">
                    {{ ` (${s.email}) ` }}
                    {{ s.rank && s.position ? `${s.rank}|${s.position}` : `${s.rank}` || `${s.position}` }}
                  </span>
                </v-chip><br>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12">
              <h4 class="mt-1">공유자</h4>
              <v-card
                outlined
                elevation="0"
                max-height="180"
                min-height="120"
                class="overflow-auto text-body-2 pa-1"
              >
                <v-chip
                  v-for="(s, i) in unSelected"
                  :key="i"
                  color="white"
                  class="d-block my-0 ml-0 mr-8"
                  @click="addSelected(s, i)"
                >
                  <v-avatar left>
                    <v-icon small>mdi-checkbox-marked-circle</v-icon>
                  </v-avatar>
                  {{ s.name }}
                  <span class="text-caption grey--text mx-1">
                    {{ ` (${s.email}) ` }}
                    {{ s.rank && s.position ? `${s.rank}|${s.position}` : `${s.rank}` || `${s.position}` }}
                  </span>
                </v-chip><br>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'
// filters
import isSpKey from '@/filters/isSpecialCharacter'

export default {
  components: {
    baseModal
  },

  // props: [ 'teamInfo' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200
    },
    // 참고: for Form
    form: {
      name: '',
      color: 'primary', // 기본컬러
      caption: '',
      staffs: [] // 등록할 유저
    },
    selected: [], // 선택된 조직원
    unSelected: [], // 선택되지 않은 조직원
    show: false, // 컬러 선택창을 보여주는 여부
    dictionary: {
      messages: ko.messages,
      attributes: {
        name: '팀명'
      }
    },
    mustChk: false, // 중복체크를 해야 하는지 여부
    chkMulti: false, // 참고: 팀명 중복 확인했는지 여부
    isOverlabed: false // 참고: 팀명이 중복 되었는지 여부
  }),

  watch: {
    'form.name': { // !! 팀명 자동감지 - 특수문자만 필터링
      handler: function (val, oldVal) {
        if (val.length > 0) { // 값이 있으면
          if (isSpKey(val)) {
            this.form.name = oldVal
            this.$refs.txtTeamName.lazyValue = oldVal
          }
          // 중복 체크 관련 초기화
          this.mustChk = true // 중복체크 해야 함
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false
        } else { // 내용이 아예 없으면 중복 체크하지 않아도 됨
          this.mustChk = false // 중복체크 안해도 됨
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false
        }
      }
    }
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    isSpKey, // 특수문자 필터링
    dummy () {
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 초기화 함수
    initDialog () {
      // form validation 초기화
      this.$validator.reset()

      this.form.name = ''
      this.form.color = 'primary' // 기본 색상값
      this.form.caption = '배정된 부서원만 정보를 공유할 수 있습니다.' // 임의팀 기본 알림값
      this.form.staffs = []

      this.mustChk = false
      this.chkMulti = false
      this.isOverlabed = false
    },
    open (title, options, team) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        this.initDialog()

        // 모든 재직중인 유저 리스트를 가져온다.
        this.getAllHoldStaffs()

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 등록 버튼
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // !! 팀명 중복체크 확인
        if (this.mustChk && !this.chkMulti) {
          this.isOverlabed = true // 중복여부를 가져오진 않았지만 수동으로 에러를 만든다.
          this.$refs.txtTeamName.focus()
          throw new Error('팀명 중복체크를 하셔야 합니다.')
        }

        this.form.staffs = this.selected // 넘길 선택된 조직원 배열
        this.form.caption = '' // 쓸데없으니 이넘은 비우자

        const { data } = await this.$axios.post('admin/firm/addTeamStaff', this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소 버튼
    cancel () {
      // 다이얼로그를 닫으면서 초기화 해야 한다!
      this.initDialog()
      this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
      this.dialog = false
    },
    setTeamColor (color) {
      this.form.color = color
      this.show = false
    },
    // 참고: 재직중인 모든 조직원 리스트 패칭
    async getAllHoldStaffs () {
      try {
        const { data } = await this.$axios.get(`admin/staff/getAllHoldStaffs`)
        if (!data.success) throw new Error(`list error: ${data.message}`)

        // !! 가져오면 모두 선택되지 않은 조직원으로 매칭해야 한다.
        this.unSelected = data.list
        this.selected = [] // 선택배열은 비워야한다(초기값)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 선택한 조직원을 공유중인 조직원 리스트로 옮긴다.
    addSelected (s, i) {
      this.selected.push(s)
      this.unSelected.splice(i, 1)
    },
    // !! 선택한 조직원을 공유중인 조직원 리스트에서 삭제한다.
    delSelected (s, i) {
      this.unSelected.push(s)
      this.selected.splice(i, 1)
    },
    // !! 팀명 중복체크 함수
    async chkTeamNameOverlap () {
      try {
        if (this.form.name.length > 0) {
          const { data } = await this.$axios.get(`admin/firm/chkTeamNameOverlap/${this.form.name}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.chk) { // 중복 되었다
            this.mustChk = true
            this.chkMulti = false
            this.isOverlabed = true
            this.$refs.txtTeamName.focus()
            throw new Error(`이미 등록된 팀명 입니다.`)
          } else {
            this.mustChk = false
            this.chkMulti = true
            this.isOverlabed = false
            this.$store.commit('SB_POP', { msg: '등록할 수 있는 팀명입니다.', color: 'success' })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
    // ,
    // // 참고: 팀명 텍스트박스의 keyup 이벤트 - 팀명 중복체크 리셋
    // async chkTeamNameOverlapReset (e) {
    //   // 체크했는지 여부와 중복여부를 리셋시킨다.
    //   this.chkMulti = false
    //   this.isOverlabed = false
    // }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
